import React from "react"
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../components/theme'
import '../css/style.css'
import Nav from '../components/_nav'
import Body from '../components/body_404'
import Footer from '../components/footer'
import SEO from '../components/seo'
import { graphql } from 'gatsby'

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

const Page404 = (props) => {

  	//console.log(props)
	const reqPath = props.location.pathname.slice(1, props.location.pathname.length)
	const rslug = decodeURI(reqPath).split("/")
	const _scat = rslug[0]
	const _spost = rslug[1] ? rslug[1].split(" ").join("-") : ""
	const _npost = rslug[1] ? rslug[1].capitalize() : ""
	const imgSrc = props.data.allImageSharp.edges[0].node.gatsbyImageData.images.fallback.src
	props.location.host = "infonutrientes.com.br"

    return	(

		<ThemeProvider theme={theme}>
			
			<>
				<SEO
					title={"INFO Nutrientes - tudo sobre a composição dos alimentos"}
					description={`${rslug.join(" ")}, tudo sobre a composição nutricional dos alimentos, calorias, carboidratos, proteínas, gorduras, vitaminas e sais minerais`}
					keywords={"composição dos alimentos, informações nutricionais, macronutrientes, vitaminas, sais minerais"}
					canonical={`https://${props.location.host}${props.location.pathname}`}
					base_url={`https://${props.location.host}`}
					post_date={"2021-03-15"}
					post_cat={`${_scat}`}
					post_cat_name={`${_scat.capitalize()}`}
					post_slug={`${_spost}`}
					post_slug_name={`${_npost}`}
					post_descp_kps={`${rslug.join(" ")}, composição dos alimentos, informações nutricionais, macronutrientes, vitaminas, sais minerais`}
					nutrinfo={false}
					location={props.location}
					image={imgSrc}
				/>
			</>

			<>
				<Nav />
			</>

			<main>
				<Body location={props.location} />
			</main>

			<>
				<Footer />
			</>

		</ThemeProvider>
    )
}

export default Page404;

export const pageQuery = graphql`
    query {
        allImageSharp(filter: {original: {src: {regex: "/infonutrientes/"}}}) {
            edges {
                node {
                    gatsbyImageData
                }
            }
        }
    }
`;